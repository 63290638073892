/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it




exports.onRouteUpdate = ( ) => {



      setTimeout(() => {
            headerInit();
      }, 100)


      // SCROLL POSITION

      function saveScrollPosition() {
            if (!localStorage.getItem('scrollPosition')) {
                  localStorage.setItem('scrollPosition', window.pageYOffset);
            }
      } // saveScrollPosition

      function resetScrollPosition() {
            if (localStorage.getItem('scrollPosition')) {
                  document.documentElement.scrollTop = localStorage.getItem('scrollPosition');
                  localStorage.removeItem('scrollPosition');
            }
      } // resetScrollPosition




      // HEADER

      function headerInit() {

            var $header = document.getElementsByClassName('c-header')[0];
            var $btn = document.getElementsByClassName('js-show-nav')[0];
            var $nav = document.getElementsByClassName('js-nav')[0];




            var popupInTimer = null;
            var popupOutTimer = null;
            var popupToggleTimer = null;

            // Show Popup

            function showPopup($el) {

                  // Clear timers
                  clearTimeout(popupOutTimer);
                  popupOutTimer = null;
                  clearTimeout(popupToggleTimer);
                  popupToggleTimer = null;

                  // Save body's scroll position
                  saveScrollPosition();

                  // Display block to popup
                  $el.style.display = 'block';

                  $el.focus();

                  // Show popup
                  $header.classList.add('is-open');

                  document.documentElement.scrollTop = 0;

            } // showPopup

            // Hide Popup

            function hidePopup($el) {

                  // Reset Timers
                  clearTimeout(popupInTimer);
                  popupInTimer = null;
                  clearTimeout(popupToggleTimer);
                  popupToggleTimer = null;

                  // Set popup's Fixed style
                  $header.classList.remove('is-open');

                  // Reset body's scroll position
                  resetScrollPosition();

                  // Hide popup after OUT animation
                  popupOutTimer = setTimeout(function () {

                        // Hide popup
                        $el.style.display = 'none';

                  }, 3000);

            } // hidePopup



            if ($btn) {
                  $btn.onclick = function () {


                        if ($btn.classList.contains('is-active') == false) {

                              // Si el Form y Nav estÃ¡n cerrados
                              showPopup($nav, true);
                              // Set Nav state
                              $btn.classList.add('is-active');

                        } else {

                              // Si estÃ¡ abierto el Form
                              hidePopup($nav, true);
                              // Set Nav state
                              $btn.classList.remove('is-active');

                        }
                        this.blur();

                        return false;
                  };
            }





      } // headerInit



}