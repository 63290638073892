// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-articulo-js": () => import("./../../../src/templates/articulo.js" /* webpackChunkName: "component---src-templates-articulo-js" */),
  "component---src-templates-articulos-js": () => import("./../../../src/templates/articulos.js" /* webpackChunkName: "component---src-templates-articulos-js" */),
  "component---src-templates-condiciones-js": () => import("./../../../src/templates/condiciones.js" /* webpackChunkName: "component---src-templates-condiciones-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-politica-de-privacidad-js": () => import("./../../../src/templates/politica-de-privacidad.js" /* webpackChunkName: "component---src-templates-politica-de-privacidad-js" */),
  "component---src-templates-solicitud-js": () => import("./../../../src/templates/solicitud.js" /* webpackChunkName: "component---src-templates-solicitud-js" */)
}

