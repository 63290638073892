module.exports = [{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"crediofertas","preview":false,"prismicToolbar":false,"pages":[{"type":"Home","match":"/","path":"/","component":"/home/runner/work/crediofertas/crediofertas/src/templates/home.js"},{"type":"Politica de privacidad","match":"/politica-de-privacidad","path":"/politica-de-privacidad","component":"/home/runner/work/crediofertas/crediofertas/src/templates/politica-de-privacidad.js"},{"type":"Terminos y condiciones","match":"/condiciones","path":"/condiciones","component":"/home/runner/work/crediofertas/crediofertas/src/templates/condiciones.js"},{"type":"Articulos","match":"/articulos","path":"/articulos","component":"/home/runner/work/crediofertas/crediofertas/src/templates/articulos.js"},{"type":"Solicitud","match":"/solicitar-prestamo","path":"/solicitar-prestamo","component":"/home/runner/work/crediofertas/crediofertas/src/templates/solicitud.js"},{"type":"Blog","match":"/articulos/:uid","path":"/articulos","component":"/home/runner/work/crediofertas/crediofertas/src/templates/articulo.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T73H58R","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"function () {\n        return {\n          pageType: window.pageType,\n        }\n      }"},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
